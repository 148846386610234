var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":"mdi-globe-model","title":"设备列表"}},[_c('v-container',{staticStyle:{"width":"90%"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_vm._l((_vm.devices),function(book){return _c('v-flex',{key:book.deviceOpenId},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticStyle:{"margin":"10px","background-color":"transparent"},attrs:{"flat":"","min-width":"135px"}},[_c('div',{staticClass:"text-center",attrs:{"elevation":hover ? 24 : 6,"flat":""}},[(book.url)?_c('v-img',{staticClass:"media",attrs:{"aspect-ratio":4 / 3,"src":book.url}}):_vm._e(),(!book.url)?_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"purple","indeterminate":""}}):_vm._e()],1),_c('div',[_vm._v(" "+_vm._s(book.deviceOpenId)+" ")]),_c('div',{staticClass:"text-no-wrap",staticStyle:{"cursor":"default"}},[_vm._v(" "+_vm._s(book.categoryName)+" ")]),_c('v-fade-transition',{on:{"leave":function($event){book.showVolume = false}}},[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-toolbar',{attrs:{"dense":"","floating":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.screen(book)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-camera-retake-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("刷新屏幕")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.volume(book)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-volume-medium")])],1)]}}],null,true)},[_c('span',[_vm._v("调整音量")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.reboot(book)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,true)},[_c('span',[_vm._v("重启")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.poweroff(book)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-power")])],1)]}}],null,true)},[_c('span',[_vm._v("关机")])])],1),(book.showVolume)?_c('v-card',[_c('v-card-text',[_c('v-slider',{attrs:{"label":"音量","thumb-color":"red","thumb-label":"always","value":book.volume},on:{"end":function($event){return _vm.setVolume(book, $event)}}})],1)],1):_vm._e()],1):_vm._e()],1)],1)]}}],null,true)})],1)}),_vm._l((20),function(i){return _c('v-flex',{key:i},[_c('v-card',{staticClass:"mx-2",staticStyle:{"margin":"10px","background-color":"transparent"},attrs:{"flat":"","min-width":"135"}})],1)})],2)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.dialog.title)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialog.show = false;
            _vm.dialog.callback(true);}}},[_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-checkbox-marked-circle ")]),_vm._v(" 确定 ")],1),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v(" 取消 "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }