export const LOGIN = "login";
export const LOGOUT = "logout";
export const CHECK_AUTH = "checkAuth";
export const CHECK_ERROR = "checkError";
export const GET_NAME = "getName";
export const GET_EMAIL = "getEmail";
export const GET_PERMISSIONS = "getPermissions";

/**
 * Fake login for testing purpose without need of real auth server
 */
export default () => {
  return {
    [LOGIN]: ({ username }) => {
      localStorage.setItem("username", username);
    },
    [LOGOUT]: () => {
      localStorage.removeItem("username");
      return Promise.resolve();
    },
    [CHECK_AUTH]: () => {
      let name = localStorage.getItem("username");

      return name
        ? Promise.resolve({
            data: {
              name,
            },
          })
        : Promise.reject();
    },
    [CHECK_ERROR]: ({ status }) => {
      if (status === 401 || status === 403) {
        localStorage.removeItem("username");
        return Promise.reject();
      }
      return Promise.resolve();
    },
    [GET_NAME]: (u) => u.name,
    [GET_PERMISSIONS]: () => ["admin", "editor"],
  };
};
