<template>
  <va-layout>
    <!-- <va-app-bar
      slot="app-bar"
      :header-menu="headerMenu"
      :profile-menu="profileMenu"
      color="secondary"
      dense
      dark
    >
    </va-app-bar> -->

    <v-app-bar
      slot="app-bar"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :clipped-right="$vuetify.breakpoint.lgAndUp"
      app
      dark
      color="secondary"
      dense
    >
      <!--
      Triggered on VAppBar icon click, use it for VaSidebar toggling or minimizing.
      @event toggle
    -->

      <v-toolbar-title class="ml-0 pl-4" style="width: 200px">
        <span class="hidden-sm-and-down">{{ title || $admin.title }}</span>
      </v-toolbar-title>
      <v-row
        v-if="headerMenu && headerMenu.length && $vuetify.breakpoint.lgAndUp"
      >
        <v-col
          v-for="(item, i) in headerMenu"
          :key="i"
          class="text-center mb-sm-0 mb-5"
          cols="auto"
        >
          <component
            :is="item.href ? 'a' : 'router-link'"
            :href="item.href"
            :to="item.link"
            class="px-3 white--text link"
            :target="item.href ? '_blank' : '_self'"
            v-text="item.text"
          ></component>
        </v-col>
      </v-row>
      <v-spacer />
      <div>
        <!-- <v-menu offset-y v-if="!disableCreate && createResourceLinks.length">
          <template v-slot:activator="{ on }">
            <v-btn icon small v-on="on" :title="$t('va.actions.create')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item
              v-for="(item, index) in createResourceLinks"
              :key="index"
              link
              :to="item.link"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->

        <v-menu offset-y v-if="$store.state.auth.user">
          <template v-slot:activator="{ on }">
            <v-btn icon small class="ml-5" v-on="on">
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>

          <v-list nav dense>
            <template v-if="getName">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title">{{
                    getName
                  }}</v-list-item-title>
                  <v-list-item-subtitle v-if="getEmail">{{
                    getEmail
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
            </template>

            <v-list-item
              v-for="(item, index) in profileMenu"
              :key="index"
              link
              :to="item.link"
              class="mt-2"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>退出</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <!-- -->
    <!-- <va-sidebar
      slot="sidebar"
      :menu="sidebarMenu"
      dark
      v-model="drawer"
      :mini-variant="mini"
    >
      <template v-slot:img="props">
        <v-img
          src="../assets/splash.jpg"
          gradient="to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)"
          v-bind="props"
        />
      </template>
    </va-sidebar> -->
    <template slot="header">
      <va-breadcrumbs></va-breadcrumbs>
      <impersonate-message></impersonate-message>
    </template>
    <!-- <va-aside slot="aside"></va-aside> -->
    <va-footer slot="footer" :menu="footerMenu">
      &copy; 2020
    </va-footer>
  </va-layout>
</template>

<script>
import ImpersonateMessage from "@/components/ImpersonateMessage";
// import nav from "../_nav";

export default {
  name: "App",
  components: {
    ImpersonateMessage,
  },
  data() {
    return {
      drawer: false,
      mini: false,
      headerMenu: [
        // ...this.$admin.getResourceLinks([
        //   "publishers",
        //   "authors",
        //   "categories",
        //   "books",
        //   "reviews",
        // ]),
      ],
      footerMenu: [],
      profileMenu: [
        {
          icon: "mdi-account",
          text: this.$t("menu.profile"),
          link: "/profile",
        },
      ],
    };
  },
  computed: {
    createResourceLinks() {
      return this.$admin.getResourceLinks(
        this.$admin.resources.map((r) => {
          return {
            name: r.name,
            action: "create",
          };
        })
      );
    },
    getName() {
      return this.$store.getters["auth/getName"];
    },
    getEmail() {
      return this.$store.getters["auth/getEmail"];
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch("api/refresh", this.$route.meta.resource);
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style scoped>
.link {
  font-size: 0.825rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}
</style>
