import axios from "axios";
import qs from "qs";
import config from "../../public/env.json";

const instance = axios.create({
  baseURL: config.server, // "https://aiot-server.dmlinker.com",
});
var _token = null;
async function getToken() {
  if (_token) return _token;
  const res = await instance.post(
    "/oauth/token",
    qs.stringify({
      grant_type: "client_credentials",
      client_id: config.appKey, // "yuntu", //"Nsia1A3y0ddG",
      client_secret: config.appSecret, // "yuntuSecret", // "opowIrH2IsoEt02na28g",
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // "Access-Control-Allow-Origin": "*",
      },
    }
  );
  console.log("token", res);
  _token = res.data.access_token;
  return _token;
}
async function post(url, body) {
  const token = await getToken();
  return await instance.post(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
}

export default {
  devices: async () => {
    const token = await getToken();
    const res = await instance.get("/api/biz/device?page=0&size=50", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("devices", res);
    return res.data.data;
  },
  screen: async (openid) => {
    const res = await post(`/api/mqtt2/device/${openid}/action/screenshot`);
    return res.data.success && res.data.screenShot;
  },
  reboot: async (openid) => {
    const res = await post(`/api/mqtt2/device/${openid}/action/power`, {
      type: "reboot",
    });
    return res.data.success;
  },
  poweroff: async (openid) => {
    const res = await post(`/api/mqtt2/device/${openid}/action/power`, {
      type: "off",
      timestamp: "now",
    });
    return res.data.success;
  },
  volume: async (openid, value) => {
    const res = await post(`/api/mqtt2/device/${openid}/action/volume`, {
      index: value,
    });
    return res.data.success;
  },
};
