<template>
  <base-material-card icon="mdi-globe-model" title="设备列表">
    <v-container style="width:90%">
      <v-layout row wrap>
        <v-flex v-for="book in devices" :key="book.deviceOpenId">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                flat
                style="margin:10px; background-color: transparent;"
                min-width="135px"
              >
                <div :elevation="hover ? 24 : 6" flat class="text-center">
                  <v-img
                    v-if="book.url"
                    :aspect-ratio="4 / 3"
                    class="media"
                    :src="book.url"
                  >
                  </v-img>
                  <v-progress-circular
                    v-if="!book.url"
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <div>
                  {{ book.deviceOpenId }}
                </div>
                <div class="text-no-wrap" style="cursor:default;">
                  {{ book.categoryName }}
                </div>
                <v-fade-transition @leave="book.showVolume = false">
                  <v-overlay v-if="hover" absolute color="#036358">
                    <v-toolbar dense floating>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="screen(book)"
                          >
                            <v-icon>mdi-camera-retake-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>刷新屏幕</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="volume(book)"
                          >
                            <v-icon>mdi-volume-medium</v-icon>
                          </v-btn>
                        </template>
                        <span>调整音量</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="reboot(book)"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>重启</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="poweroff(book)"
                          >
                            <v-icon>mdi-power</v-icon>
                          </v-btn>
                        </template>
                        <span>关机</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-card v-if="book.showVolume">
                      <v-card-text>
                        <v-slider
                          label="音量"
                          thumb-color="red"
                          thumb-label="always"
                          :value="book.volume"
                          @end="setVolume(book, $event)"
                        ></v-slider>
                      </v-card-text>
                    </v-card>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-flex>
        <v-flex v-for="i in 20" :key="i">
          <v-card
            flat
            style="margin:10px; background-color: transparent;"
            class="mx-2"
            min-width="135"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog.show" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ dialog.title }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            @click="
              dialog.show = false;
              dialog.callback(true);
            "
          >
            <v-icon dark right>
              mdi-checkbox-marked-circle
            </v-icon>
            确定
          </v-btn>
          <v-btn color="red" dark @click="dialog.show = false">
            取消
            <v-icon dark right>
              mdi-cancel
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-material-card>
</template>

<script>
import service from "@/providers/iot";
var offlineImange = require("@/assets/offline-icon-6.png");
export default {
  props: ["resource", "title"],
  data() {
    return {
      dialog: {
        show: false,
        title: "",
        callback: null,
      },
      devices: [],
      showVolume: false,
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      var list = await service.devices();
      list.forEach((item) => {
        item.url = "";
        item.showVolume = false;
        item.volume = null;
      });
      this.devices = list;
      if (this.devices && this.devices.length) {
        this.devices.forEach(this.screen);
      }
    },
    async screen(device) {
      try {
        device.url = "";
        const image = await service.screen(device.deviceOpenId);
        if (image) {
          device.url = "data:image/jpeg;base64," + image;
        } else {
          device.url = offlineImange;
        }
      } catch (e) {
        device.url = offlineImange;
      }
      // console.log(`image:${device.deviceOpenId}`, device.url);
    },
    async volume(device) {
      device.showVolume = true;
    },
    async setVolume(device, value) {
      if (await service.volume(device.deviceOpenId, value)) {
        device.volume = value;
      }
    },
    async reboot(device) {
      this.dialog.title = "确定要重启吗？";
      this.dialog.callback = async () => {
        await service.reboot(device.deviceOpenId);
      };
      this.dialog.show = true;
    },
    async poweroff(device) {
      this.dialog.title = "确定要关机吗？";
      this.dialog.callback = async () => {
        await service.poweroff(device.deviceOpenId);
      };
      this.dialog.show = true;
    },
  },
};
</script>
<style scoped>
.media {
  border-radius: 3%;
}
.media:hover {
  box-shadow: 0 0 3pt 2pt #ff5555;
  border-radius: 3%;
  cursor: pointer;
}
.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  width: 100%;
  position: absolute;
}
.block {
  display: block !important;
  height: 24px;
}
</style>
